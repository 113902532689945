import webAppLogo from './assets/web-app.png'

export function Capabilities() {

    return (
        <div className="card flex justify-content-center capabilties">
            <div className="capability">
                <div className="logo">
                    <img src={webAppLogo} alt="" />
                </div>
                <h3 style={{color: 'rgb(248, 221, 99)'}}>Web Apps</h3>
            </div>
            <div className="capability">
                <div className="logo">
                    <img src='https://nodejs.org/static/images/logo.svg' alt="" />
                </div>
                <h3 style={{color: 'rgb(2, 110, 0)'}}>Node.JS Apps</h3>
            </div>
            <div className="capability">
                <div className="logo">
                    <img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K' alt="" />
                </div>
                <h3 style={{color: 'rgb(97, 218, 251)'}}>React Apps</h3>
            </div>
            <div className="capability">
                <div className="logo">
                    <img src='https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a69f118df70ad7828d4_icon_clyde_blurple_RGB.svg' alt="" />
                </div>
                <h3 style={{color: '#7289DA'}}>Discord Bots</h3>
            </div>
            <div className="capability">
                <div className="logo">
                    <img src='https://forum.fivem.net/uploads/default/original/4X/7/5/e/75ef9fcabc1abea8fce0ebd0236a4132710fcb2e.png' alt="" />
                </div>
                <h3 style={{color: 'rgb(255,167,109)'}}>FiveM Scripts</h3>
            </div>
            <div className="capability">
                <div className="logo">
                    <img src='https://www.minecraft.net/etc.clientlibs/minecraft/clientlibs/main/resources/android-icon-192x192.png' alt="" />
                </div>
                <h3 style={{color: 'rgb(59,133,38)'}}>Minecraft Scripts</h3>
            </div>
        </div>
    )
}