import { useEffect, useRef, useState } from 'react'
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom'

import './Style.css'
import profilePicture from '../../assets/pfp.png'
import { Reasons } from './Reasons';
import { ReasonsList } from './ReasonsList';

import { Divider } from '../../Components/Divider'
import { Footer } from '../../Components/Footer';
import { Capabilities } from './Capabilities';
import { Avatar } from 'primereact/avatar';
import { Divider as Seperator } from 'primereact/divider';
import { Button } from 'primereact/button';


const Delay = (ms) => new Promise(res => setTimeout(res, ms));

const dynamicTextConfig = [
    {
        value: 'Web Apps',
        color: 'rgb(248, 221, 99)',
    },
    {
        value: 'Node.JS Apps',
        color: 'rgb(2, 110, 0)',
    },
    {
        value: 'React Apps',
        color: 'rgb(97, 218, 251)',
    },
    {
        value: 'Discord Bots',
        color: '#7289DA',
    },
    {
        value: 'FiveM Scripts',
        color: 'rgb(255,167,109)'
    },
    {
        value: 'Minecraft Skripts',
        color: 'rgb(59,133,38)',
    }
]

export function Home() {
    const [dynamicText, setDynamicText] = useState(dynamicTextConfig[0].value);
    const dynamicColor = useRef(dynamicTextConfig[0].color);
    const dynamicIndex = useRef(0);
    const dynamicTextElement = useRef(null);
    const focus = useRef(true);
    const updating = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            updateDynamicText();
        }, 3000);
        window.addEventListener('blur', focusLost)
        window.addEventListener('focus', focusTab);

        return () => {
            window.removeEventListener('blur', focusLost);
            window.removeEventListener('focus', focusTab);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="background">
                <div className="background-img"></div>
                <div className="background-title">
                    <motion.div initial={{ opacity: 0, transform: 'translateY(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.2 } }}>
                        <Avatar image={profilePicture} size="xlarge" style={{ width: '20vh', height: '20vh' }} shape="circle" />
                    </motion.div>
                    <motion.div initial={{ opacity: 0, transform: 'translateY(25px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.6 } }}>
                        <h1 className='large'>Looking for a developer?</h1>
                        <h1>You've come to the right place.</h1>

                        <h1>I can develop <span ref={dynamicTextElement} className='dynamic-text' style={{ color: dynamicColor.current }}>{dynamicText}</span></h1>
                    </motion.div>
                </div>
            </div>

            <Divider height={'10vh'} />
            <a href="#introduction"><i className="pi pi-arrow-circle-down scroll-btn"></i></a>
            <Divider height={'15vh'} />
            <Divider height={'10vh'} id={'introduction'} />
            <div className="home">

                <motion.div initial={{ opacity: 0, transform: 'translateX(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.4 } }}>
                    <h2>INTRODUCTION</h2>
                    <h1>Hello, my name is <span className='highlight'>Vito</span>.</h1>
                    {/* <Avatar image={profilePicture} size="xlarge" shape="circle" /> */}
                    <h2>I am a professional full stack developer with experience in JavaScript <br />
                        and expertise in frameworks like React, Node.JS and more. <br />
                        I'm a quick learner and collaborate closely with clients to create <br />
                        efficient, scalable and user-friendly solutions. <br />
                        Let's work together to bring your ideas to life!
                    </h2>
                </motion.div>

                <Divider height={'15vh'} />
                <Seperator align="center"><i className="pi pi-circle seperator"></i></Seperator>
                <Divider height={'15vh'} />

                <motion.div initial={{ opacity: 0, transform: 'translateX(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.4 } }}>
                    <h2>CAPABILITIES</h2>
                    <h1>What I can <span className='highlight'>do:</span></h1>
                    <Capabilities />
                </motion.div>

                <Divider height={'15vh'} />
                <Seperator align="center"><i className="pi pi-circle seperator"></i></Seperator>
                <Divider height={'15vh'} />

                <motion.div initial={{ opacity: 0, transform: 'translateX(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.4 } }}>
                    <h2>WHY SHOULD YOU PICK ME</h2>
                    <h1>Why choose <span className='highlight'>me:</span></h1>
                    <Reasons />
                    <ReasonsList />
                </motion.div >

                <Divider height={'15vh'} />
                <Seperator align="center"><i className="pi pi-circle seperator"></i></Seperator>
                <Divider height={'15vh'} />

                <div className="center">
                    <motion.div initial={{ opacity: 0, transform: 'translateY(-20px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.4 } }}>
                        <Button label="Contact Me" icon="pi pi-envelope" size="large" onClick={() => {
                            document.querySelector('.content').classList.add('transition')
                            setTimeout(() => {
                                navigate('/contact')
                                window.scrollTo(0, 0);
                                document.querySelector('.content').classList.remove('transition')
                            }, 350);
                        }} />
                    </motion.div>
                </div>
                <Divider height={'15vh'} />
            </div>
            <Footer />
        </>
    )

    async function updateDynamicText() {
        if (focus.current !== true) return;
        if (updating.current === true) return;
        updating.current = true;
        dynamicTextElement.current.classList.add('hidden')
        await Delay(700)

        let newIndex = dynamicIndex.current + 1;

        if (!dynamicTextConfig[newIndex]) newIndex = 0

        dynamicColor.current = dynamicTextConfig[newIndex].color
        setDynamicText(dynamicTextConfig[newIndex].value)

        // await Delay(200)
        dynamicTextElement.current.classList.add('visible')
        dynamicTextElement.current.classList.remove('hidden')

        await Delay(400)
        dynamicTextElement.current.classList.remove('visible')
        // if (dynamicIndex.current + 1 >= dynamicTextConfig.length) dynamicIndex.current = 0
        // else 
        dynamicIndex.current = newIndex

        await Delay(2000)
        updating.current = false;
        updateDynamicText()
    }

    function focusTab() {
        if (focus.current === true) return;
        focus.current = true;
        updateDynamicText();
    }
    function focusLost() {
        focus.current = false;
    }
}