import { Timeline } from 'primereact/timeline';


export function ReasonsList() {
    const events = [
        {
            status: 'Experienced',
        },
        {
            status: 'Professional'
        },
        {
            status: 'Capable'
        },
        {
            status: 'Reliable',
        },
        {
            status: 'Adaptable'
        },
        {
            status: 'Versatile'
        },
        {
            status: 'Passionate'
        },
        {
            status: 'Perfectionist'
        },
        {
            status: 'Knowledgeable'
        },
        {
            status: 'Adept'
        },
        {
            status: 'Receptive'
        }
    ];
        
    return (
        <div className="card reasons-list">
            <Timeline value={events} content={(item) => item.status} align="alternate" />
        </div>
    )
}
        