
import { useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';


export function Reasons() {
    const [data] = useState([
        {
            label: '',
            expanded: true,
            children: [
                {
                    label: 'Competence',
                    expanded: true,
                    children: [
                        {
                            label: 'Professional'
                        },
                        {
                            label: 'Experienced'
                        },
                        {
                            label: 'Capable'
                        },
                        {
                            label: 'Reliable'
                        }
                    ]
                },
                {
                    label: 'Personal Skills',
                    expanded: true,
                    children: [
                        {
                            label: 'Passionate'
                        },
                        {
                            label: 'Versatile'
                        },
                        {
                            label: 'Perfectionist'
                        },
                        {
                            label: 'Adaptable'
                        }
                    ]
                },
                {
                    label: 'Knowedge',
                    expanded: true,
                    children: [
                        {
                            label: 'Adept'
                        },
                        {
                            label: 'Knowledgeable'
                        }
                    ]
                },
                {
                    label: 'Sociability',
                    expanded: true,
                    children: [
                        {
                            label: 'Receptive'
                        }
                    ]
                }
            ]
        }
    ]);

    return (
        <div className='card overflow-x-auto reasons'>
            <OrganizationChart value={data} />
        </div>
    )
}
        