import { Routes, Route } from 'react-router-dom'

import './App.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/themes/arya-purple/theme.css'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


import { Navbar } from './Components/Navbar';
import { Home } from './Pages/Home/Home'
import { Projects } from './Pages/Projects'
import { Store } from './Pages/Store';
import { Contact } from './Pages/Contact';

function App() {
  return (
    <div className='App'>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/store' element={<Store />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
