export function Footer() {
    return (
        <div className="footer">
            <a href={process.env.REACT_APP_POLICY_LINK} target="_blank" rel="noreferrer"><span className="link" style={{float: 'left'}}>Terms</span></a>
            <a href={process.env.REACT_APP_DISCORD_LINK} target="_blank" rel="noreferrer"><i className="pi pi-discord link"></i></a>
            <a href={process.env.REACT_APP_EMAIL_LINK} target="_blank" rel="noreferrer"><i className="pi pi-envelope link"></i></a>
            <a href={process.env.REACT_APP_GITHUB_LINK} target="_blank" rel="noreferrer"><i className="pi pi-github link"></i></a>
            {/* <span style={{float: 'right'}}>Created By <span className="link">Vito</span>.</span> */}
        </div>
    )
}