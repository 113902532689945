import { useNavigate, useLocation } from 'react-router-dom'

import logo from '../assets/pfp.png'
import './Style.css'

import { Menubar } from 'primereact/menubar';

export function Navbar() {
    const navigate = useNavigate();
    const location = useLocation()
    const items = [
        {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            className: location.pathname === '/' && 'active',
            command: () => redirect('/')

        },
        {
            label: 'Projects',
            icon: 'pi pi-fw pi-th-large',
            className: location.pathname === '/projects' && 'active',
            command: () => redirect('/projects')

        },
        {
            label: 'Store',
            icon: 'pi pi-fw pi-shopping-bag',
            className: location.pathname === '/store' && 'active',
            command: () => redirect('/store')
        },
        {
            label: 'Contact',
            icon: 'pi pi-fw pi-discord',
            className: location.pathname === '/contact' && 'active',
            command: () => redirect('/contact')
        },
    ];
    const start = <img alt="logo" src={logo} height="40" className="mr-2 nav-logo"></img>;

    return (
        <div className="card" style={{position: 'sticky', top: 0, zIndex: 999999}}>
            <Menubar model={items} start={start} />
        </div>
    )

    function redirect(url) {
        if (location.pathname === url) return;
        document.querySelector('.content').classList.add('transition')
        setTimeout(() => {
            navigate(url)
            window.scrollTo(0, 0);
            document.querySelector('.content').classList.remove('transition')
        }, 350);
    }
}