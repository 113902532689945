import { useRef, useState } from "react";
import axios from 'axios'

import { motion } from "framer-motion"
import { Divider } from "../Components/Divider";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Divider as Seperator } from 'primereact/divider';
import { Footer } from "../Components/Footer";


export function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [discord, setDiscord] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const msgs = useRef(null);
    return (
        <>
            <Divider height={'5vh'} />
            <div className="contact">
                <motion.div initial={{ opacity: 0, transform: 'translateX(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 0.4 } }}>
                    <div className="contact-form">
                        <h1>Contact Me</h1>
                        <Messages ref={msgs} />
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Your Name</label>
                            <InputText value={name} onChange={(e) => setName(e.target.value)} placeholder="What's your name?" required disabled={loading} />
                            <label htmlFor="email">Your Email</label>
                            <InputText value={email} onChange={(e) => setEmail(e.target.value)} placeholder="What's your email?" required keyfilter={'email'} disabled={loading} />
                            <label htmlFor="email">Your Discord <span style={{ color: 'rgb(79, 81, 96)' }}>(Optional)</span></label>
                            <InputText value={discord} onChange={(e) => setDiscord(e.target.value)} placeholder="Username#TAG" disabled={loading} />
                            <label htmlFor="email">Your Message</label>
                            <InputTextarea value={message} onChange={(e) => setMessage(e.target.value)} rows={5} cols={30} placeholder="Describe your idea..." required disabled={loading} />
                            <Button label="Submit" icon="pi pi-check" raised style={{ float: 'right' }} loading={loading} />
                        </form>
                    </div>
                </motion.div>
                <div className="contact-icons">
                    <motion.div initial={{ opacity: 0, transform: 'translateX(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 1 } }}>
                        <a href={process.env.REACT_APP_DISCORD_LINK} target="_blank" rel="noreferrer"><i className="pi pi-discord"></i></a>
                        <Seperator layout="vertical" id="contact-icons-seperator" />
                        <a href={process.env.REACT_APP_EMAIL_LINK} target="_blank" rel="noreferrer"><i className="pi pi-envelope"></i></a>
                    </motion.div>
                </div>
            </div>
            <div className="contact-icons-mobile">
                <motion.div initial={{ opacity: 0, transform: 'translateX(-50px)' }} whileInView={{ opacity: 1, transform: 'translate(0px)', transition: { duration: 0.5, delay: 1 } }}>
                    <Divider height={'20vh'} />
                    <a href={process.env.REACT_APP_DISCORD_LINK} target="_blank" rel="noreferrer"><i className="pi pi-discord"></i></a>
                    <Divider height={'10vh'} />
                    <a href={process.env.REACT_APP_EMAIL_LINK} target="_blank" rel="noreferrer"><i className="pi pi-envelope"></i></a>
                    <Divider height={'10vh'} />
                </motion.div>
            </div>
            <Divider height={'20vh'} />
            <Footer />
        </>
    )

    function handleSubmit(e) {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) return showError('Email adress is not valid!');

        const discordRegex = /^([a-zA-Z0-9_-]{2,32})#(\d{4})$/;
        if (discord && !discordRegex.test(discord)) return showError('Discord username and tag are not valid!')
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/contact`, {
            name: name,
            email: email,
            discord: discord,
            message: message
        }).then(res => {
            showSuccess(res.data.message);
            setName('');
            setEmail('');
            setDiscord('');
            setMessage('');
        }).catch(err => {
            showError(err?.response?.data?.message || err?.message + ': ' + err?.response?.statusText, err?.response?.data?.type)
        }).finally(() => setLoading(false))
    }

    function showError(message, type) {
        msgs.current.clear();
        setTimeout(() => {
            msgs.current.show({ sticky: true, severity: type || 'error', detail: message, closable: true });
        }, 100);
    }

    function showSuccess(message) {
        msgs.current.clear();
        setTimeout(() => {
            msgs.current.show({ sticky: true, severity: 'success', detail: message, closable: true });
        }, 100);
    }
}